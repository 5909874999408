<template>
  <Banner bg-image="yellow-bg.jpg" talk-img="create-safety-plan.png">
    <template v-slot:title> Create a plan to keep yourself safe </template>
    <template v-slot:subtitle>
      Creating a safety plan can help you or others better support you if you
      are in crisis. Your plan may change over time as you find coping tools
      that work better than others or discover new ones.</template
    >
    <template v-slot:description
      >Let’s look at an example safety plan and what yours could include.
    </template>
  </Banner>

  <section>
    <div class="max-w-6xl px-4 mx-auto sm:px-6">
      <div class="mb-6 text-2xl font-bold call-text md:text-3xl text-blue">
        How to create a safety plan
      </div>
      <p>
        You can create a safety plan on your own or with someone you trust who
        may be able to offer some perspective and suggestions. You can also give
        a copy of your safety plan to someone you trust, if you are comfortable
        with it.
      </p>
    </div>
  </section>

  <section class="py-10 my-8 safety-sect md:py-8">
    <div class="max-w-6xl p-8 mx-auto bg-blue-100 rounded-md sm:px-6">
      <div class="items-center safety-text md:flex">
        <div class="safety-img">
          <img src="../../assets/images/girl-with-headphones.png" alt="" />
        </div>
        <div class="s-div">
          <div class="mb-4 text-2xl font-bold text-blue">
            Here is an example of safety plan
          </div>
          <p class="mb-5">
            This is just one example of what a safety plan looks like but it
            might give you some ideas on how you can format your own safety
            plan.
          </p>

          <a
            href="/files/safety-plan.pdf"
            download
            class="font-bold text-green"
          >
            Download example safety plan
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10.922"
              height="28"
              viewBox="0 0 10.922 28"
              class="inline-block"
            >
              <path
                id="Path_4072"
                data-name="Path 4072"
                d="M831.1,508.029l8.712-8.711a7.544,7.544,0,0,0,0-10.67l-8.62-8.62a26.475,26.475,0,0,1-.091,28Z"
                transform="translate(-831.098 -480.029)"
                fill="currentColor"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </section>

  <section class="my-8 talk-sect">
    <div class="max-w-6xl px-4 mx-auto sm:px-6">
      <div class="mb-6 text-2xl font-bold text-center text-blue">
        Ideas for what to include in your safety plan
      </div>
      <div class="pt-8">
        <Collapsible>
          <template v-slot:header>
            <span class="text-2xl">
              Identify triggers and sources of stress</span
            >
          </template>

          <template v-slot:content>
            <div class="px-8 bg-blue-100">
              <p class="pt-4">
                If you’ve experienced thoughts of suicide before, you may be
                able to recognize specific patterns, situations or events that
                make you feel less like yourself (e.g., noticing yourself
                withdrawing from friends or losing interest in things you
                usually enjoy).
              </p>
              <p class="py-4">
                Knowing what triggers, you or causes you to feel stressed, sad,
                overwhelmed, etc. can be important information to help keep you
                safe.
              </p>
            </div>
          </template>
        </Collapsible>

        <br />

        <Collapsible>
          <template v-slot:header>
            <span class="text-2xl">
              Notice warning signs that you’re in crisis</span
            >
          </template>
          <template v-slot:content>
            <div class="px-8 bg-blue-100">
              <p class="py-4">
                Similar to identifying triggers, you may notice thoughts,
                feelings, behaviors and body sensations that mark a shift in
                your emotional well-being and ability to cope. This could
                include, for example, a change in your mood, feeling angry,
                having difficulty sleeping, not eating etc.
              </p>
            </div>
          </template>
        </Collapsible>

        <br />

        <Collapsible>
          <template v-slot:header>
            <span class="text-2xl"> Note your go-to coping strategies</span>
          </template>
          <template v-slot:content>
            <div class="px-8 bg-blue-100">
              <p class="py-4">
                Think about and use strategies you’ve found the most helpful for
                working through difficult emotions and check in with yourself
                about what could be most helpful to try in the moment (listening
                to Indigenous songs, smudging, etc.).
              </p>
            </div>
          </template>
        </Collapsible>
        <br />
        <Collapsible>
          <template v-slot:header>
            <span class="text-2xl"> Create physical safety</span>
          </template>
          <template v-slot:content>
            <div class="px-8 bg-blue-100">
              <p class="py-4">
                Consider ways to keep yourself safe wherever you are. Think of
                places you feel comfortable going to outside of your home (e.g.,
                a friend’s house, a park in your area) where you can find
                support if you’re struggling. Creating a safety plan can help
                you be prepared.
              </p>
            </div>
          </template>
        </Collapsible>
        <br />
        <Collapsible>
          <template v-slot:header>
            <span class="text-2xl">Talk to an elder</span>
          </template>
          <template v-slot:content>
            <div class="px-8 bg-blue-100">
              <p class="py-4">
                Elders are knowledge keepers and play an important role in
                imparting the importance of
                <router-link
                  :to="{ name: 'ExploreCulturalHealingPractices' }"
                  class="underline text-green"
                  >Indigenous knowledge</router-link
                >
                to health and wellbeing. Make a list of elders you could reach
                out to.
              </p>
            </div>
          </template>
        </Collapsible>
        <br />
        <Collapsible>
          <template v-slot:header>
            <span class="text-2xl">Talk to a professional</span>
          </template>
          <template v-slot:content>
            <div class="px-8 bg-blue-100">
              <p class="py-4">
                Mental health professionals are trained to listen and offer
                advice for how to cope with what you are feeling. For your
                safety plan, it could be helpful to
                <router-link
                  class="underline text-green"
                  :to="{ name: 'FindACounselor' }"
                  >research a counsellor or therapist
                </router-link>
                in your area so that you have someone available to call or meet
                with if you are experiencing a crisis.
              </p>
            </div>
          </template>
        </Collapsible>
      </div>
    </div>
  </section>

  <FooterCard
    img="try-another-coping.png"
    text="View Tools & resources"
    name="ToolsAndResources"
  >
    <template v-slot:header>Try another coping strategy</template>
    <template v-slot:content>
      Different coping strategies resonate with different people.
      <br />
      Discover more coping strategies and add to your toolbox.
    </template>
  </FooterCard>
</template>

<script>
import Banner from "@/components/Banner.vue";
import Collapsible from "@/components/Collapsible.vue";
import FooterCard from "@/components/FooterCard.vue";
export default {
  components: {
    Banner,
    Collapsible,
    FooterCard,
  },
  name: "CreateASafetyPlan",
};
</script>

<style scoped>
.safety-img {
  width: 200px;
  height: 200px;
  margin-right: 50px;
}
.safety-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.s-div {
  width: calc(100% - 200px);
}
@media (max-width: 767px) {
  .safety-img {
    margin: 0 auto 25px;
  }
  .s-div {
    width: 100%;
  }
}
</style>
